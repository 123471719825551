import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67df80bc = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _77f6f51b = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _813ae188 = () => interopDefault(import('../pages/koppeling/index.vue' /* webpackChunkName: "pages/koppeling/index" */))
const _ba17cf36 = () => interopDefault(import('../pages/koppelingen/index.vue' /* webpackChunkName: "pages/koppelingen/index" */))
const _f8d82280 = () => interopDefault(import('../pages/sectoren/index.vue' /* webpackChunkName: "pages/sectoren/index" */))
const _764053d3 = () => interopDefault(import('../pages/faq/_slug.vue' /* webpackChunkName: "pages/faq/_slug" */))
const _84a82418 = () => interopDefault(import('../pages/koppeling/_slug.vue' /* webpackChunkName: "pages/koppeling/_slug" */))
const _cdf21fe6 = () => interopDefault(import('../pages/koppelingen/_id.vue' /* webpackChunkName: "pages/koppelingen/_id" */))
const _4ccea128 = () => interopDefault(import('../pages/sectoren/_id.vue' /* webpackChunkName: "pages/sectoren/_id" */))
const _eb086458 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _69377e2c = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _67df80bc,
    name: "contact"
  }, {
    path: "/faq",
    component: _77f6f51b,
    name: "faq"
  }, {
    path: "/koppeling",
    component: _813ae188,
    name: "koppeling"
  }, {
    path: "/koppelingen",
    component: _ba17cf36,
    name: "koppelingen"
  }, {
    path: "/sectoren",
    component: _f8d82280,
    name: "sectoren"
  }, {
    path: "/faq/:slug",
    component: _764053d3,
    name: "faq-slug"
  }, {
    path: "/koppeling/:slug",
    component: _84a82418,
    name: "koppeling-slug"
  }, {
    path: "/koppelingen/:id",
    component: _cdf21fe6,
    name: "koppelingen-id"
  }, {
    path: "/sectoren/:id",
    component: _4ccea128,
    name: "sectoren-id"
  }, {
    path: "/",
    component: _eb086458,
    name: "index"
  }, {
    path: "/*",
    component: _69377e2c,
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
